"use strict";

angular.module("informaApp")
    .service("FilterService", function (BaseApiService, ConstantsSvc, TableGroupsRoutes) {
        var current = { filter: null, params: null, field: null };

        return {
            getCurrentFilter: function() {
                return { filter: current.filter, params: current.params, field: current.field };
            },

            getResultCount: function(params) {
                return BaseApiService.post('filter/count', params);
                // return $http.post(ConstantsSvc.API.URL + "filter/count", params);
            },

            getFilterWithNames: function (filter) {
                return BaseApiService.post('filter/names', filter)
                .then(response => {
                    return response.data.data;
                });
                // return $http.post(ConstantsSvc.API.URL + 'filter/names', filter)
                
            },

            getResultByAll: function (params) {
                current.params = params;
                current.filter = 'getResultByAll';

                return BaseApiService.post('table/all', params);
                // return $http.post(ConstantsSvc.API.URL + "table/all", params);
            },

            getResultByDisease: function (params) {
                current.params = params;
                current.field = 'indications';
                current.filter = 'getResultByDisease';

                return BaseApiService.post('table/diseases', params);
                // return $http.post(ConstantsSvc.API.URL + "table/diseases", params);
            },

            getResultBySubDisease: function (params) {
                current.params = params;
                current.field = 'indications';
                current.filter = 'getResultBySubDisease';

                return BaseApiService.post('table/subdiseases', params);
                //return $http.post(ConstantsSvc.API.URL + "table/subdiseases", params);
            },

            getResultByIndication: function (params) {
                current.params = params;
                current.field = 'indications';
                current.filter = 'getResultByIndication';

                return BaseApiService.post('table/indications', params);
                // return $http.post(ConstantsSvc.API.URL + "table/indications", params);
            },

            getResultByCompany: function (params) {
                current.params = params;
                current.field = 'companies';
                current.filter = 'getResultByCompany';

                return BaseApiService.post('table/companies', params);
                // return $http.post(ConstantsSvc.API.URL + "table/companies", params);
            },

            getResultByDrugClasses: function (params) {
                current.params = params;
                current.field = 'drugclasses';
                current.filter = 'getResultByDrugClasses';

                return BaseApiService.post('table/drugclasses', params);
                // return $http.post(ConstantsSvc.API.URL + "table/drugclasses", params);
            },

            getResultByMolecule: function (params) {
                current.params = params;
                current.field = 'molecules';
                current.filter = 'getResultByMolecule';

                return BaseApiService.post('table/molecules', params);
                // return $http.post(ConstantsSvc.API.URL + "table/molecules", params);
            },

            getResultByTarget: function (params) {
                current.params = params;
                current.field = 'targets';
                current.filter = 'getResultByTarget';
                
                return BaseApiService.post('table/targets', params);
                // return $http.post(ConstantsSvc.API.URL + "table/targets", params);
            },

            getResultByDrug: function (params) {
                current.params = params;
                current.field = 'drugnames';
                current.filter = 'getResultByDrugName';
                
                return BaseApiService.post('table/drugs', params);
                // return $http.post(ConstantsSvc.API.URL + "table/drugs", params);
            },

            getResultByRoute: function (params) {
                current.params = params;
                current.field = 'routes';
                current.filter = 'getResultByRoute';
                
                return BaseApiService.post('table/routes', params);
                // return $http.post(ConstantsSvc.API.URL + "table/routes", params);
            },

            getResult(tableGroupRouteId, filter) {
                const tableGroupRoute = Object.values(TableGroupsRoutes)
                    .find(group => group.id === tableGroupRouteId);

                if (!tableGroupRoute) {
                    return null;
                }

                return BaseApiService.post(`table/${tableGroupRoute.route}`, filter)
                .then(response => response.data.data);
                //return $http.post(ConstantsSvc.API.URL + `table/${tableGroupRoute.route}`, filter)
                    
            }
        };
    });
